export default {
  path: 'material-jumbo',
  children: [
    {
      path: '',
      name: 'costing.material-jumbo',
      component: () => import('./list/MaterialJumboListPage.vue')
    },
    {
      path: 'add',
      name: 'costing.material-jumbo.add',
      component: () => import('./form/MaterialJumboFormPage.vue')
    }
  ]
}
